<template>
  <section>
    <b-modal
      id="modal-addslot"
      v-model="show"
      centered
      size="lg"
      header-class="border-bottom-0"
      modal-class="addslot-modal"
      content-class="add-slot"
      :no-close-on-backdrop="noCloseOnBackdrop"
      :no-close-on-esc="noCloseOnEsc"
    >
      <LoadingSpinner v-if="loading" />
      <template
        v-slot:modal-header=""
      >
        <h4 class="font-weight-bold">
          Add new slot
        </h4>
      
        <b-button
          type="button"
          variant="default"
          class="p-0 ml-auto"
          @click="handleClose"
        >
          <icon-close
            height="24"
            width="24"
          />
        </b-button>
      </template>

      <b-container
        fluid
        class="pl-3 pr-3"
      >
        <b-form>
          <b-row class="mb-1">
            <b-col 
              cols="12" 
              lg="6"
            >
              <div class="row">        
                <div class="col-md-2 col-xs-6">
                  <img
                    src="../../assets/images/date.png"
                  >
                </div>
                <div class="col-md-10 col-xs-6">
                  <p class="text-uppercase mt-2">
                    date
                  </p>
                </div>
              </div>
              <div class="clearfix" />

              <div class="font-open-sans mb-2">
                <div class="font-weight-600">
                  When?
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-input
                      id="timeslotdate"
                      v-model="form.timeslotdateinput"
                      v-validate="{ required: true }"
                      :state="validateState('timeslotdate')"
                      name="timeslotdate"
                      type="text"
                      placeholder="Date"
                      autocomplete="off"
                      :class="[style['input-field'], 'bg-transparent', 'border']"
                      data-vv-as="date"
                      readonly
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="form.timeslotdate"
                        button-only
                        right
                        locale="en"
                        aria-controls="timeslotdate"
                        :min="min"
                        :hide-header="hideHeader"
                        :show-decade-nav="showDecadeNav"
                        @context="onContext"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("timeslotdate") }}
                  </span>
                </div>
              </div>

              <div class="row">        
                <div class="col-12 col-lg-6 col-xs-6">
                  <div class="font-open-sans">
                    <div class="font-weight-600">
                      Start Time
                    </div>
                    <div class="bank-search-input">
                      <b-input-group class="mt-1">
                        <b-form-timepicker
                          id="starttime"
                          v-model="form.starttime"
                          v-validate="{ required: true }"
                          :state="validateState('starttime')"
                          name="starttime"
                          type="text"
                          :class="[style['input-field'], 'bg-transparent', 'border']"
                          locale="en"
                          placeholder="Start time"
                          data-vv-as="start time"
                          :hide-header="hideHeader"
                          :show-decade-nav="showDecadeNav"
                          :no-close-button="noClosebutton"
                        />
                      </b-input-group>
                      <span
                        id="input-1-live-agree_process_personal_data"
                        class="valdiation_error"
                      >
                        {{ veeErrors.first("starttime") }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6 col-xs-6">
                  <div class="font-open-sans">
                    <div class="font-weight-600">
                      End Time
                    </div>
                    <div class="bank-search-input">
                      <b-input-group class="mt-1">
                        <b-form-timepicker
                          id="endtime"
                          v-model="form.endtime"
                          v-validate="{ required: true }"
                          :state="validateState('endtime')"
                          name="endtime"
                          type="text"
                          :class="[style['input-field'], 'bg-transparent', 'border']"
                          locale="en"
                          placeholder="End time"
                          data-vv-as="end time"
                          :hide-header="hideHeader"
                          :show-decade-nav="showDecadeNav"
                          :no-close-button="noClosebutton"
                        />
                      </b-input-group>
                      <span
                        id="input-1-live-agree_process_personal_data"
                        class="valdiation_error"
                      >
                        {{ veeErrors.first("endtime") }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="font-open-sans mb-2 mt-3">
                <div class="font-weight-600">
                  Time Zone
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-select
                      id="timezone"
                      v-model="form.timezone"
                      v-validate="{ required: true }"
                      :state="validateState('timezone')"
                      name="timezone"
                      :options="timezoneoptions"
                      :class="[style['field'], 'bg-transparent', 'border']"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("timezone") }}
                  </span>
                </div>
              </div>
              <div
                v-if="call_type === 1"
                class="font-open-sans mb-2 mt-1"
              >
                <div class="font-weight-600">
                  Total Spots Open For Fans
                </div>
                <div class="bank-search-input">
                  <b-input-group
                    class="mt-1"
                  >
                    <b-form-input
                      id="members"
                      v-model="form.members"
                      v-validate="{ required: true, 'fans': true }"
                      name="fans"
                      type="text"
                      :step="0.1"
                      placeholder="Total Spots Open For Fans"
                      data-vv-as="Total Spots Open For Fans"
                      style="padding:7px"
                      :class="[style['field'], 'bg-transparent', 'border']"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("fans") }}
                  </span>
                </div>
              </div>
              <div
                v-if="call_type === 2"
                class="font-open-sans mb-2"
              >
                <div class="font-weight-600">
                  Stream Type
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-1">
                    <b-form-select
                      id="streamtype"
                      v-model="form.streamtype"
                      v-validate="{ required: true }"
                      :options="streamtypes"
                      :state="validateState('streamtype')"
                      name="streamtype"
                      :class="[style['field'], 'bg-transparent', 'border']"
                      @change="handleStreamPrice"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("streamtype") }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col 
              cols="12" 
              lg="6"
            >
              <div class="row">        
                <div class="col-md-2 col-xs-6">
                  <img
                    src="../../assets/images/slottype.png"
                  >
                </div>
                <div class="col-md-10 col-xs-6">
                  <p class="text-uppercase mt-1 ml-2">
                    Slot Type 
                    <b-button
                      id="tooltip-target-1"
                      :class="style['slot-type-tooltip']"
                    >
                      ?
                    </b-button>
                    <b-tooltip
                      target="tooltip-target-1"
                      triggers="hover"
                    >
                      A live stream allows you to go live in front of either all followers (paid and free) or just paid or just free. Video Calls are where you can decide the total number of Fans allowed on the call and set a fee per Fan to participate. These Video calls are scheduled. 
                    </b-tooltip>
                  </p>
                </div>
              </div>

              <div class="clearfix" />

              <div class="font-open-sans mb-5">
                <div class="font-weight-600">
                  Category
                </div>
                <div class="bank-search-input">
                  <b-input-group class="mt-2">
                    <b-form-select
                      id="category"
                      v-model="form.category"
                      v-validate="{ required: true }"
                      :options="options"
                      :state="validateState('category')"
                      style="height: 55px"
                      name="category"
                      :class="[style['field'], 'bg-transparent', 'border']"
                      @change="handlePrice"
                    />  
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("category") }}
                  </span>
                </div>
              </div>

              <div class="row">        
                <div class="col-md-2 col-xs-6">
                  <img
                    src="../../assets/images/price.png"
                  >
                </div>
                <div class="col-md-10 col-xs-6">
                  <p class="text-uppercase mt-1 ml-3">
                    Price for fan
                  </p>
                </div>
              </div>

              <div class="font-open-sans mb-2 mt-1">
                <div class="font-weight-600">
                  Price Per Participant
                </div>
                <div class="bank-search-input">
                  <b-input-group
                    prepend="$"
                    class="mt-1 slot-popup-price"
                  >
                    <b-form-input
                      id="price"
                      v-model="form.price"
                      v-validate="isValidate ? { required: true, 'decimal': true, 'price': true } : {required: true}"
                      :state="isValidate ? validateState('price') : ''"
                      name="price"
                      type="text"
                      :step="0.1"
                      placeholder="Price"
                      :class="[style['input-field'], 'bg-transparent', 'border']"
                      :disabled="isDisabled"
                    />
                  </b-input-group>
                  <span
                    id="input-1-live-agree_process_personal_data"
                    class="valdiation_error"
                  >
                    {{ veeErrors.first("price") }}
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            pill
            class="px-5 text-uppercase float-right"
            @click="handleSave"
          >
            Add
          </b-button>
          <b-button
            variant="default"
            pill
            class="mr-4 text-uppercase float-right"
            @click="handleClose"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import style from "../../styles/settings.module.scss";
import IconClose from '../../assets/svg-import/close.svg';
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import { APIUrlList } from "../../js/api_url";
import { _sendResponse } from "../../js/common-function";
import LoadingSpinner from "../Common/LoadingSpinner";
import { setTimezoneOptions } from '../../api/meeting';
import moment from 'moment';
import { Validator } from 'vee-validate';
Validator.extend('price', {
    getMessage: "Please enter a valid price.",
    validate: value => (/^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/).test(value)
});

Validator.extend('fans', {
    getMessage: "Please enter a valid number.",
    validate: value => (/^[0-9]*[1-9][0-9]*$/).test(value)
});
const now = new Date();
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
const minDate = new Date(today);
minDate.setDate(minDate.getDate());
export default {
    name: 'AddSlot',
    components: {IconClose,LoadingSpinner},
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    data() {
        return {
            style,
            isDisabled: true,
            form: {
                timeslotdate: "",
                timeslotdateinput: "",
                starttime:"",
                endtime:"",
                category: null,
                price:"",
                timezone:"",
                streamtype:null,
                members: null,
            },
            hideHeader: true,
            showDecadeNav: false,
            min: minDate,
            options: [
                { value: null, text: 'Select Category' },
                { value: 1, text: 'Video Call' },
                { value: 2, text: 'Live Streaming'}
            ],
            streamtypes:[
                {value:null, text:'Select Type'},
                {value:1, text:'Private'},
                {value:2, text:'Public'}
            ],
            timezones:[],
            timezoneoptions: [],
            timezonesoffset:[],
            loading: false,
            noCloseOnBackdrop:true,
            noClosebutton:true,
            noCloseOnEsc: true,
            account_type: localStorage.getItem("account-type"),
            call_type: '',
            isValidate: true,
        };
    },
    async created (){
        if (this.account_type == 2) {
            let timezone = await  setTimezoneOptions();
            if (timezone.timezones.length > 0){
                this.timezonesoffset = timezone.offset;                               
                this.timezoneoptions = timezone.timezones;
            }
            // this.settimezoneoptions();
        }
    },
    methods: {
        onReset() {
            // event.preventDefault();
            // Reset our form values

            this.form.timeslotdateinput = "";
            this.form.timeslotdate = "";
            this.form.starttime = "";
            this.form.endtime = "";
            this.form.category = null;
            this.form.price = "";
            this.form.timezone = "";
            this.form.streamtype = null;
            this.form.members = null;
            this.call_type= '';
        },
        onContext(ctx) {
            if (ctx.selectedDate != null){
            //this.formatted = ctx.selectedFormatted;
            //this.selected = ctx.selectedYMD;
                this.form.timeslotdateinput = moment(ctx.selectedYMD).format('MMMM DD, YYYY');
            }
        },
        validateState(ref) {
            if (
                this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
            ) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        handleClose() {
            this.onReset();
            this.$emit('onClose');
        },
        handleSave() {
            this.$validator.validateAll().then((result) => {
                if (!result) {
                    return;
                }

                const formattedDate = moment(this.form.timeslotdate).format('MM/DD/YYYY');
                //return false;
                let formData = {};
                formData.date = formattedDate;
                formData.start_time = this.form.starttime;
                formData.end_time = this.form.endtime;
                formData.time_zone = this.form.timezone;
                formData.time_zone_offset = (this.timezonesoffset.length > 0) ? this.timezonesoffset[this.form.timezone] : "";
                formData.price = this.form.price;
                formData.call_type = this.form.category;
                this.call_type === 2 ? formData.stream_type = this.form.streamtype : formData.no_of_gab_allow = this.form.members;
                this.loading = true;
                axios
                    .post(API_BASE_URL + APIUrlList.ADDMEETINGSLOT, formData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("user-token"),
                        },
                    })
                    .then((response) => {
                        this.loading = false;
                        if (response.data.status == false){
                            _sendResponse("error", response.data.message);
                        } else {
                            _sendResponse("success", response.data.message);
                            this.$emit('onClose');
                            this.onReset();
                            this.$emit('addSlot');
                        }
                    });
            });
        },
        handlePrice(value){
            this.isDisabled = false;
            this.isValidate = true;
            let callPrice = localStorage.getItem('videoCall') !== null && localStorage.getItem('videoCall') !== "null" ? localStorage.getItem('videoCall') : 0; 
            let streamPrice = localStorage.getItem('liveStream') !== null && localStorage.getItem('liveStream') !== "null" ? localStorage.getItem('liveStream') : 0; 
            this.form.price = value === 1 ? callPrice : streamPrice;
            this.call_type = value;
        },
        handleStreamPrice(value){
            if (value === 2){
                this.form.price = 0;
                this.isValidate = false;
                this.isDisabled = true;
            } else {
                this.isDisabled = false;
                this.isValidate = true;
                this.form.price = localStorage.getItem('liveStream') !== null && localStorage.getItem('liveStream') !== "null" ? localStorage.getItem('liveStream') : 0;
            }
        },
    }
};
</script>