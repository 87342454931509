<template>
  <section>
    <b-container class="mb-4 text-center px-5 py-0 shadow">
      <div :class="[style['register_wrapper'], 'my-md-5 my-4 p-3']">
        <b-row class="justify-content-center">
          <b-col
            xl="6"
            lg="8"
          >
            <div class="text-center pt-5 mb-5 text-center">
              <img
                class="mb-3"
                src="../../assets/imagesv2/ic-forgot-password.png"
                alt=""
              >
              <h1 class="title display-5 font-weight-bold mb-4">
                No events yet
              </h1>
              <p class="font-open-sans mb-0 font-weight-bold">
                {{ accountType === "2" ? 'Let your fans know when they can call you.' : 'Book videocalls with popz to fill the calendar.' }}
              </p>
            </div>
            <b-row class="mb-5 justify-content-center">
              <b-col
                xl="8"
                lg="10"
              >
                <b-button
                  v-if="accountType === '2'"
                  ref="button"
                  variant="dark"
                  class="btn-pop text-uppercase font-weight-bold"
                  squared
                  block
                  @click="showAddSlot"
                >
                  add videocall slot
                </b-button>
                <b-button
                  v-if="accountType === '1'"
                  ref="button"
                  variant="dark"
                  class="btn-pop text-uppercase font-weight-bold"
                  squared
                  block
                  @click="()=>$router.push('/celebsearch')"
                >
                  Browse Popz
                </b-button>
              </b-col>
            </b-row>
            <div class="text-right mb-5" />
          </b-col>
        </b-row>
      </div>
    </b-container>
  </section>
</template>


<script>
import style from "../../styles/feed.module.scss";
export default {
    props: {
        showAddSlot:{
            type: Function,
            default:()=>{},
        },
    },
    data() {
        return {
            style,
            accountType: localStorage.getItem('account-type')
        };
    }
};
</script>