<template>
  <section class="w-100">
    <loading-spinner v-if="loading" />
    <CancelSlot
      :show="showCancel"
      @onClose="closeCancelSlot"
      @onDelete="handleCancel"
    />
    <FansList
      :show="showModal"
      :callData="callData"
      :gabList="gabList"
      @onClose="closeList"
      @onSelect="selectedGab"
    />
    <div>
      <AddSlot
        :show="show"
        @onClose="handleClose"
        @addSlot="getCalenderList"
      />
      <UpdateSlot
        ref="updateSlotRef"
        :show="showEditSlot"
        :slotId="editSlotId"
        @onClose="handleCloseUpdateSlot"
        @addSlot="getCalenderList"
      />
      <b-container
        class="py-sm-4 mb-5 header-container"
      >
        <b-row>
          <b-col
            md="6"
            :class="['pull-left']"
          >
            <h2 :class="['font-weight-bold mb-md-5 mb-4 mt-4 display-4']">
              Calendar
            </h2>
          </b-col>
          <b-col
            md="6"
            :class="['pull-right text-right']"
          >
            <b-button
              v-if="account_type === '2' && eventList.length"
              ref="button"
              variant="dark"
              :class="['btn-pop text-uppercase font-weight-bold mt-md-5 py-2 px-5 display-4 w-75']"
              squared
              block
              @click="showAddSlot"
            >
              Add Meeting Slot
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div
      class="mb-5"
    >
      <b-container
        v-if="eventList.length"
        :class="['px-4', style['meeting-calendar-container']]"
      >
        <FullCalendar
          ref="fullCalendar"
          :options="calendarOptions"
        >
          <template v-slot:eventContent="arg">
            <div class="d-flex">
              <div :class="style['event-content']">
                <b-button
                  :id="`tooltip-target${arg.event.id}`"
                  :class="[style['calendar-label'],'bg-transparent border-0']"
                  @click="handleRedirection(arg.event.extendedProps)"
                >
                  {{ arg.event.title }}
                </b-button>
                <b-tooltip
                  v-if="account_type === '2' && arg.event.extendedProps.my_slots"
                  :target="`tooltip-target${arg.event.id}`"
                  triggers="hover"
                >
                  <p class="mb-1">
                    {{ arg.event.extendedProps.start_original_date }}
                  </p>
                  <p class="mb-1">
                    {{ arg.event.extendedProps.start_original_time + '-' + arg.event.extendedProps.end_original_time }}
                  </p>
                  <p class="mb-0">
                    {{ arg.event.extendedProps.timezone }}
                  </p>
                </b-tooltip>
              </div>
              <div
                v-if="account_type === '2' && arg.event.title.includes('Video') && arg.event.extendedProps.my_slots"
                class="mt-1 ml-5 slots-count"
              >
                {{ arg.event.extendedProps.gabs_remaining }}
              </div>
              <div
                v-if="account_type === '1' && arg.event.extendedProps.gabName || account_type === '2' && arg.event.extendedProps.my_slots === false"
                class="text-right ml-auto joincall-btn"
              >
                <b-button
                  v-if="arg.event.extendedProps.call_status == 3"
                  variant="light"
                  class="px-4 border bg-transparent text-uppercase"
                  pill
                  disabled
                >
                  Call Cancelled
                </b-button>
                <b-button
                  v-else-if="arg.event.extendedProps.remaining_time.minutes > 10"
                  variant="light"
                  class="px-4 border bg-transparent"
                  pill
                  disabled
                >
                  {{ arg.event.extendedProps.remaining_time.days !== 0 ? arg.event.extendedProps.remaining_time.leftDays : arg.event.extendedProps.remaining_time.hours !== 0 ? `${arg.event.extendedProps.remaining_time.hours} hours left` : `${arg.event.extendedProps.remaining_time.minutes} minutes left` }}
                </b-button>
                <b-button
                  v-else-if="(arg.event.extendedProps.remaining_time.minutes <= 10 && arg.event.extendedProps.remaining_time.minutes >= 0) || (arg.event.extendedProps.remaining_time.call_duration_left > 0 && arg.event.extendedProps.remaining_time.minutes < 0)"
                  :class="['px-4 text-uppercase', style['join-btn']]"
                  variant="primary"
                  pill
                  @click="handleCall(arg.event)"
                >
                  {{ arg.event.title.includes('Video') ? 'Join Call' : 'Join Stream' }}
                </b-button>
              </div>
              <div
                v-if="account_type === '2' && arg.event.extendedProps.remaining_time.days >= 7 && arg.event.extendedProps.my_slots"
                class="text-right ml-auto joincall-btn"
              >
                <b-button
                  v-if="arg.event.extendedProps.call_status == 3"
                  variant="light"
                  class="px-4 border bg-transparent text-uppercase"
                  pill
                  disabled
                >
                  Slot Cancelled
                </b-button>
                <b-button
                  v-else
                  variant="light"
                  :class="['px-4 border text-uppercase', style['cancel-btn']]"
                  pill
                  @click="openCancelSlot(arg.event.extendedProps.slot_id)"
                >
                  Cancel
                </b-button>
              </div>
              <template v-if="account_type == '2' && arg.event.extendedProps.gabName && arg.event.extendedProps.remaining_time.days < 7 && arg.event.extendedProps.my_slots">
                <div
                  v-if="arg.event.extendedProps.remaining_time.minutes > 10 && arg.event.extendedProps.remaining_time.days < 7 && arg.event.extendedProps.my_slots"
                  class="text-right ml-auto joincall-btn"
                >
                  <b-button
                    variant="light"
                    :class="['px-4 border bg-transparent', style['time-left']]"
                    pill
                    disabled
                  >
                    {{ arg.event.extendedProps.remaining_time.days !== 0 ? arg.event.extendedProps.remaining_time.leftDays : arg.event.extendedProps.remaining_time.hours !== 0 ? `${arg.event.extendedProps.remaining_time.hours} hours left` : `${arg.event.extendedProps.remaining_time.minutes} minutes left` }}
                  </b-button>
                </div>
                <div
                  v-else-if="(arg.event.extendedProps.remaining_time.minutes <= 10 && arg.event.extendedProps.remaining_time.minutes >= 0) || (arg.event.extendedProps.remaining_time.call_duration_left > 0 && arg.event.extendedProps.remaining_time.minutes < 0)"
                  class="text-right ml-auto"
                >
                  <b-button
                    :class="['px-4 text-uppercase', style['start-call-btn']]"
                    variant="primary"
                    pill
                    @click="handleCall(arg.event)"
                  >
                    {{ arg.event.title.includes('Video') ? 'Start Videocall' : 'Start Stream' }}
                  </b-button>
                </div>
              </template>
              <div
                v-if="arg.event.extendedProps.remaining_time.minutes > 60 && arg.event.extendedProps.user_id == userId && arg.event.extendedProps.my_slots && arg.event.extendedProps.call_status != 3"
                class="text-right ml-auto joincall-btn test"
              >
                <b-button
                  variant="light"
                  :class="['px-4 border text-uppercase', 'meeting-edit-btn']"
                  pill
                  @click="openEditSlot(arg.event.extendedProps.slot_id)"
                >
                  Edit
                </b-button>
              </div>
            </div>
          </template>
        </FullCalendar>
      </b-container>
      <b-container v-if="showNoMeeting">
        <no-meeting
          :showAddSlot="showAddSlot"
          :accountType="account_type"
        />
      </b-container>
    </div>
  </section>
</template>


<script>
import style from "../../styles/meetings.module.scss";
import AddSlot from "../../components/Meetings/AddSlot";
import '@fullcalendar/core/vdom'; // solves problem with Vite
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import NoMeeting from '../../components/Meetings/NoMeeting';
import { getUserCalenderList, cancelSlots, getGabList } from '../../api/meeting';
import LoadingSpinner from '../../components/Common/LoadingSpinner.vue';
import FansList from '../../components/Meetings/FansList';
import CancelSlot from '../../components/Meetings/CancelSlot';
import UpdateSlot from "../../components/Meetings/UpdateSlot";
export default {
    name: 'Meetings',
    components: {
        AddSlot,
        FullCalendar,
        NoMeeting,
        LoadingSpinner,
        FansList,
        CancelSlot,
        UpdateSlot
    },
    data() {
        return {
            style,
            show: false,
            showEditSlot: false,
            account_type: localStorage.getItem('account-type').toString(),
            userId: localStorage.getItem("userId"),
            calendarOptions: "",
            eventList: [],
            loading: true,
            showNoMeeting:false,
            days:0,
            hours:0,
            minutes:0,
            showModal: false,
            callData:{},
            showCancel:false,
            slotId:'',
            editSlotId: '',
            gabList:[],
        };
    },
    watch:{
        eventList:function(newVal){
            this.eventList = newVal;
            if (this.eventList.length === 0){
                this.showNoMeeting = true;
            }
        }
    },
    async created (){
        this.getCalenderList();
        if (document.getElementById('newsFeed') !== null){
            document.getElementById('newsFeed').firstChild.classList.remove('router-link-active');
        }
    },
    methods: {
        showAddSlot() {
            this.show = true;
        },
        handleClose() {
            this.show = false;
        },
        closeList(){
            this.showModal = false;
        },
        async getCalenderList() {
            this.showNoMeeting = false;
            this.eventList = await getUserCalenderList(this.account_type);
            if (this.eventList.length > 0){
                this.callCalendar();
            } else if (this.eventList.length == 0){
                this.showNoMeeting = true;
            }
            this.loading = false;
        },
        callCalendar() {
            this.calendarOptions = {
                schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
                plugins: [
                    dayGridPlugin,
                    interactionPlugin,
                    timeGridPlugin,
                    listPlugin,
                    resourceTimelinePlugin,
                ],
                headerToolbar: {
                    left: 'today',
                    center: 'prev,title,next',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                },
                timeZone: 'IST',
                editable: false,
                initialView: 'listWeek',
                events: this.eventList,
            };
        },
        handleCall(data){
            this.$router.push({path:`/call/${data.extendedProps.bookingId}`});
        },
        async handleRedirection(event){
            if (this.account_type === '2' && event.bookingId && event.stream_type !== 'Public' && event.my_slots){
                let gabList = await getGabList(event.slot_id);
                this.gabList = gabList;
                this.callData = event;
                this.showModal = true;
            } else if (this.account_type === '1' || this.account_type === '2' && event.my_slots === false){
                this.$router.push(`/feed/${event.username}`);
            }
        },
        selectedGab(data){
            // console.log(data, '123321')
            let encodedId = window.btoa(data.slot_id);
            let encodedGabId = window.btoa(data.gab.id);

            this.$router.push(`/profile/${encodedId}/${encodedGabId}`);
        },
        async handleCancel(){
            //let me = this;
            this.loading = true;
            let canceled = await cancelSlots(this.slotId);
            if (canceled && Object.keys(canceled).length > 0){
                //this.loading = false;
                /* this.eventList.findIndex(function(current, index){
                    if (current.id === canceled.id){
                        me.eventList.splice(index, 1);
                    }
                }); */
                this.getCalenderList();
            }
        },
        closeCancelSlot(){
            this.showCancel = false;
        },
        openCancelSlot(id){
            this.slotId = id;
            this.showCancel = true;
        },
        openEditSlot(id){
            this.editSlotId = id.toString();
            this.showEditSlot = true;
            this.$refs.updateSlotRef.getSlotDetails(this.editSlotId);
        },
        handleCloseUpdateSlot(){
            this.showEditSlot = false;
        }
    },
};
</script>

