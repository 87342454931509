<template>
  <b-modal
    id="modal-center"
    v-model="show"
    centered
    hide-footer
    size="md"
    body-class="text-center pb-5"
    content-class="rounded-0 border-0"
    header-class="border-bottom"
    :no-close-on-backdrop="true"
  >
    <template
      v-slot:modal-header=""
    >
      <span class="h3 font-weight-bold">
        <p class="mb-0">Bookings</p>
        <p class="font-weight-light h6 mb-0"> {{ callData.call_type === 1 ? 'Video call at ' : 'Streaming at ' }} {{ callData.day }}, {{ callData.start_original_time }} - {{ callData.end_original_time }}</p>
      </span>
      <b-button
        type="button"
        variant="default"
        class="p-0 ml-auto"
        @click="handleClose"
      >
        <icon-close
          height="24"
          width="24"
        />
      </b-button>
    </template>
    <template>
      <div
        v-for="(gab) in gabList"
        :key="gab.gab.id"
        class="border-bottom mt-2"
        style="cursor:pointer"
        @click="handleSelect(gab)"
      >
        <b-row>
          <b-col md="3">
            <b-img
              :class="['mb-3',style['gab-image']]"
              :src="gab.gab.profile_pic_url"
              @error="setPlaceholderProfileImg"
            />
          </b-col>
          <b-col :class="['text-left pt-3', style['gab-name']]">
            {{ gab.gab.name + ' ' + gab.gab.surname }}
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
import IconClose from '../../assets/svg-import/close.svg';
import style from '../../styles/shopping.module.scss';
import { setPlaceholderProfileImg } from '../../js/common-function';
export default {
    name: 'FansList',
    components: {IconClose},
    props: {
        show: {
            type: Boolean,
            required: true,
            default: true
        },
        callData: {
            type: Object,
            default: () => {},
        },
        gabList: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            style,
            profilePic: localStorage.getItem('fabProfilePic'),
            setPlaceholderProfileImg
        };
    },
    methods: {
        handleClose() {
            this.$emit('onClose');
        },
        handleSelect(gab){
            this.$emit('onSelect',gab);
        }
    }
};
</script>
